import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "setup-configuration"
    }}>{`Setup configuration`}</h1>
    <h2 {...{
      "id": "add-setup-for-aws-rds-instances"
    }}>{`Add Setup for AWS RDS Instances`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`aws-rds-instances`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter the rest of the required fields and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Boolean flag determining if RDS instance snapshots should be replicated to vault account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication region`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`An AWS region in which snapshots replicas will be stored.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Share snapshot back`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Causes snapshots replicas stored in a `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account to be shared with a `}<inlineCode parentName="td">{`PROTECTED`}</inlineCode>{` account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replicas encryption KMS key ARN or alias`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Alias or ARN of a KMS key which will be used to encrypt snapshots replicas in `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account. Key must exist in replication region. Correct value is a string without `}<inlineCode parentName="td">{`alias/`}</inlineCode>{` prefix or ARN. For KMS key in ARN format and different replication region, it is required to provide the `}<em parentName="td">{`Default KMS key replacement`}</em>{` setting.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Default KMS key replacement`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`If a snapshot located in a `}<inlineCode parentName="td">{`PROTECTED`}</inlineCode>{` account, is going to be re-encrypted in source account and copied into `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account (e.g. source snapshot is encrypted by a default AWS managed KMS key) then you need to provide a custom KMS key (alias, ARN or key id) that will be used to re-encrypt the snapshot before replication.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Vault account id`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication region is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`ID of a vault account used for storing snapshots replicas.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Force encryption of unencrypted resources replicas`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Forces snapshot's replica to be encrypted using KMS key specified in Replicas encryption KMS key setting, even if original resource is unencrypted.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2 {...{
      "id": "add-setup-for-aws-rds-clusters"
    }}>{`Add Setup for AWS RDS Clusters`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`aws-rds-clusters`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter the rest of the required fields and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Boolean flag determining if RDS cluster snapshots should be replicated to vault account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication region`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`An AWS region in which snapshots replicas will be stored.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Share snapshot back`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Causes snapshots replicas stored in a `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account to be shared with a `}<inlineCode parentName="td">{`PROTECTED`}</inlineCode>{` account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replicas encryption KMS key ARN or alias`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Alias or ARN of a KMS key which will be used to encrypt snapshots replicas in `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account. Key must exist in replication region. Correct value is a string without `}<inlineCode parentName="td">{`alias/`}</inlineCode>{` prefix or ARN. For KMS key in ARN format and different replication region, it is required to provide the `}<em parentName="td">{`Default KMS key replacement`}</em>{` setting.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Default KMS key replacement`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`If a snapshot located in a `}<inlineCode parentName="td">{`PROTECTED`}</inlineCode>{` account, is going to be re-encrypted in source account and copied into `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account (e.g. source snapshot is encrypted by a default AWS managed KMS key) then you need to provide a custom KMS key (alias, ARN or key id) that will be used to re-encrypt the snapshot before replication.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Vault account id`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`ID of a vault account used for storing snapshots replicas.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2 {...{
      "id": "add-setup-for-aws-s3"
    }}>{`Add Setup for AWS S3`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`aws-s3`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter the rest of the required fields and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication region`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`An AWS region in which S3 objects replicas storing bucket will be created.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Vault account id`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication region is provided`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Id of vault account used for storing objects replicas.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication role ARN`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication region is provided`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`ARN of a role which will be used by AWS to perform cross/same-region replication.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replicated prefixes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Prefixes of S3 objects which will be replicated. If empty then all objects stored in an S3 bucket will be replicated. The list should be JSON-encoded, e.g. `}<inlineCode parentName="td">{`["pref1", "pref2"]`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replicas encryption KMS key ARN or alias`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Alias or ARN of a KMS key which will be used to encrypt S3 objects replicas. Key must exist in replication region. Correct value is a string without `}<inlineCode parentName="td">{`alias/`}</inlineCode>{` prefix or ARN.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Override replica owner`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`If `}<inlineCode parentName="td">{`true`}</inlineCode>{` then the owner of replicated objects is set to `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication region is provided`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`If `}<inlineCode parentName="td">{`true`}</inlineCode>{` then replication for bucket is enabled.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replicas storing bucket name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of an S3 bucket which will store replicas of snapshots. If this value is not provided AutoBackup will create bucket.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2 {...{
      "id": "add-setup-for-aws-route53"
    }}>{`Add Setup for AWS Route53`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`aws-route53`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter rest of the required fields and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Storing bucket name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of an S3 bucket which will be used to store information about backed up Route53 settings.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`If `}<inlineCode parentName="td">{`true`}</inlineCode>{` then replication for Route53 snapshots is enabled.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication region`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`The region in which backup replica is stored.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replicas storing bucket name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of an S3 bucket which will store replicas of snapshots .`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Vault account id`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Id of vault account used for storing backup replicas.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replicas encryption KMS key ARN or alias`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Alias or ARN of a KMS key which will be used to encrypt a snapshot replica. Key must exist in replication region. Correct value is a string without `}<inlineCode parentName="td">{`alias/`}</inlineCode>{` prefix or ARN.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Force encryption of unencrypted resources replicas`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Forces snapshot's replica to be encrypted using KMS key specified in Replicas encryption KMS key setting, even if original resource is unencrypted.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2 {...{
      "id": "add-setup-for-aws-dynamodb"
    }}>{`Add Setup for AWS DynamoDB`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`aws-dynamodb`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter rest required fields to the setup form and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication region`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`The region in which backup replica is stored. If is not specified, then backup replica is not created.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Vault account id`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication region is provided`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Id of vault account used for storing backup replicas.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replicas storing bucket name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication region is provided`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of an S3 bucket which will store replicas of snapshots .`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Temporary DynamoDB table read capacity units`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Decides how many DynamoDB table read capacity units will be used during replication. If this setting is omitted then the table will be read in `}<inlineCode parentName="td">{`ON DEMAND`}</inlineCode>{` mode.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2 {...{
      "id": "add-setup-for-aws-efs"
    }}>{`Add Setup for AWS EFS`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`aws-efs`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter rest required fields to the setup form and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Skip creating primary snapshot through AWS Backup`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No (deprecated)`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Skip creating primary snapshot.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Backup vault name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of AWS Backup vault used to make EFS snapshots.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ARN of role for backup vault`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`ARN of a role which will be passed to AWS Backup to execute EFS backup job.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replicas storing bucket name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No (deprecated)`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a bucket used to store EFS filesystem replicas.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Subnet ID`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No (deprecated)`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`ID of a subnet in which an EC2 instance copying data from EFS to S3 will be launched.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Security group ID`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No (deprecated)`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`ID of a security group which will be attached to an EC2 instance copying data from EFS to S3.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ARN of an instance profile`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No (deprecated)`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`ARN of an EC2 instance profile which will be used to launch an EC2 instance copying data from EFS to S3.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Boolean flag determining if EFS snapshots should be replicated to vault account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Vault account id`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Id of vault account used for storing backup replicas.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication region`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`An AWS region in which snapshot replicas will be stored.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ARN of a replica backup vault`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`An ARN of a AWS Backup Vault used to replicate EFS snapshot.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2 {...{
      "id": "add-setup-for-aws-ec2"
    }}>{`Add Setup for AWS EC2`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`aws-ec2`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter rest required fields to the setup form and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Boolean flag determining if EC2 snapshots should be replicated to vault account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication region`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`An AWS region in which snapshot replicas will be stored.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Share snapshot back`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Causes snapshots replicas stored in a `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account to be shared with a `}<inlineCode parentName="td">{`PROTECTED`}</inlineCode>{` account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replicas encryption KMS key ARN or alias`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Alias or ARN of a KMS key which will be used to encrypt snapshots replicas in `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account. Key must exist in replication region. Correct value is a string without `}<inlineCode parentName="td">{`alias/`}</inlineCode>{` prefix or ARN.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Default KMS key replacement`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`If a snapshot located in a `}<inlineCode parentName="td">{`PROTECTED`}</inlineCode>{` account, is going to be re-encrypted in source account and copied into `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account (e.g. source snapshot is encrypted by a default AWS managed KMS key) then you need to provide a custom KMS key (alias, ARN or key id) that will be used to re-encrypt the snapshot before replication.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Vault account id`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`ID of a vault account used for storing snapshot replicas.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Force encryption of unencrypted resources replicas`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Forces snapshot's replica to be encrypted using KMS key specified in Replicas encryption KMS key setting, even if original resource is unencrypted.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2 {...{
      "id": "add-setup-for-aws-ebs"
    }}>{`Add Setup for AWS EBS`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`aws-ebs`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter rest required fields to the setup form and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Boolean flag determining if EBS snapshots should be replicated to vault account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication region`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`An AWS region in which snapshot replicas will be stored. If not specified then snapshots will not be replicated.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Share snapshot back`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Causes snapshots replicas stored in a `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account to be shared with a `}<inlineCode parentName="td">{`PROTECTED`}</inlineCode>{` account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replicas encryption KMS key ARN or alias`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Alias or ARN of a KMS key which will be used to encrypt snapshots replicas in `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account. Key must exist in replication region. Correct value is a string without `}<inlineCode parentName="td">{`alias/`}</inlineCode>{` prefix or ARN.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Default KMS key replacement`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`If a snapshot located in a `}<inlineCode parentName="td">{`PROTECTED`}</inlineCode>{` account, is going to be re-encrypted in source account and copied into `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account (e.g. source snapshot is encrypted by a default AWS managed KMS key) then you need to provide a custom KMS key (alias, ARN or key id) that will be used to re-encrypt the snapshot before replication.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Vault account id`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`ID of a vault account used for storing snapshot replicas.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Force encryption of unencrypted resources replicas`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Forces snapshot's replica to be encrypted using KMS key specified in Replicas encryption KMS key setting, even if original resource is unencrypted.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2 {...{
      "id": "add-setup-for-aws-redshift"
    }}>{`Add Setup for AWS Redshift`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`aws-redshift`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter rest required fields to the setup form and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2 {...{
      "id": "add-setup-for-aws-china-rds-instances"
    }}>{`Add Setup for AWS China RDS Instances`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`aws-cn-rds-instances`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter rest required fields to the setup form and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Boolean flag determining if RDS instance snapshots should be replicated to vault account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication region`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`An AWS region in which snapshots replicas will be stored.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Share snapshot back`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication region is provided`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Causes snapshots replicas stored in a `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account to be shared with a `}<inlineCode parentName="td">{`PROTECTED`}</inlineCode>{` account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replicas encryption KMS key ARN or alias`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Alias or ARN of a KMS key which will be used to encrypt snapshots replicas in `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account. Key must exist in replication region. Correct value is a string without `}<inlineCode parentName="td">{`alias/`}</inlineCode>{` prefix or ARN.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Default KMS key replacement`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`If a snapshot located in a `}<inlineCode parentName="td">{`PROTECTED`}</inlineCode>{` account, is going to be re-encrypted in source account and copied into `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account (e.g. source snapshot is encrypted by a default AWS managed KMS key) or for resources in Beijing region (sharing encrypted snapshot is not supported in this region) then you need to provide a custom KMS key (alias, ARN or key id) that will be used to re-encrypt the snapshot before replication.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Vault account id`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`ID of a vault account used for storing snapshots replicas.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Force encryption of unencrypted resources replicas`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Forces snapshot's replica to be encrypted using KMS key specified in Replicas encryption KMS key setting, even if original resource is unencrypted.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2 {...{
      "id": "add-setup-for-aws-china-rds-clusters"
    }}>{`Add Setup for AWS China RDS Clusters`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`aws-cn-rds-clusters`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter rest required fields to the setup form and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Boolean flag determining if RDS cluster snapshots should be replicated to vault account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication region`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`An AWS region in which snapshots replicas will be stored.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Share snapshot back`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Causes snapshots replicas stored in a `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account to be shared with a `}<inlineCode parentName="td">{`PROTECTED`}</inlineCode>{` account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replicas encryption KMS key ARN or alias`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Alias or ARN of a KMS key which will be used to encrypt snapshots replicas in `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account. Key must exist in replication region. Correct value is a string without `}<inlineCode parentName="td">{`alias/`}</inlineCode>{` prefix or ARN.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Default KMS key replacement`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`If a snapshot located in a `}<inlineCode parentName="td">{`PROTECTED`}</inlineCode>{` account, is going to be re-encrypted in source account and copied into `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account (e.g. source snapshot is encrypted by a default AWS managed KMS key) or for resources in Beijing region (sharing encrypted snapshot is not supported in this region) then you need to provide a custom KMS key (alias, ARN or key id) that will be used to re-encrypt the snapshot before replication.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Vault account id`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`ID of a vault account used for storing snapshots replicas.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2 {...{
      "id": "add-setup-for-aws-china-s3"
    }}>{`Add Setup for AWS China S3`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`aws-cn-s3`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter rest required fields to the setup form and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication region`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`An AWS region in which S3 objects replicas storing bucket will be created.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Vault account id`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication region is provided`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Id of vault account used for storing objects replicas.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication role ARN`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication region is provided`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`ARN of a role which will be used by AWS to perform cross/same-region replication.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replicated prefixes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Prefixes of S3 objects which will be replicated. If empty then all objects stored in an S3 bucket will be replicated.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replicas encryption KMS key ARN or alias`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Alias or ARN of a KMS key which will be used to encrypt S3 objects replicas. Key must exist in replication region. Correct value is a string without `}<inlineCode parentName="td">{`alias/`}</inlineCode>{` prefix or ARN.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Override replica owner`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`If `}<inlineCode parentName="td">{`true`}</inlineCode>{` then the owner of replicated objects is set to `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication region is provided`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`If `}<inlineCode parentName="td">{`true`}</inlineCode>{` then replication for bucket is enabled.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replicas storing bucket name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of an S3 bucket which will store replicas of snapshots. If this value is not provided AutoBackup will create bucket.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2 {...{
      "id": "add-setup-for-aws-china-dynamodb"
    }}>{`Add Setup for AWS China DynamoDB`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`aws-cn-dynamodb`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter rest required fields to the setup form and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication region`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`The region in which backup replica is stored. If is not specified, then backup replica is not created.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Vault account id`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication region is provided`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Id of vault account used for storing backup replicas.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replicas storing bucket name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication region is provided`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of an S3 bucket which will store replicas of snapshots .`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Temporary DynamoDB table read capacity units`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Decides how many DynamoDB table read capacity units will be used during replication. If this setting is omitted then the table will be read in `}<inlineCode parentName="td">{`ON DEMAND`}</inlineCode>{` mode.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2 {...{
      "id": "add-setup-for-aws-china-efs"
    }}>{`Add Setup for AWS China EFS`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`aws-cn-efs`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter rest required fields to the setup form and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Skip creating primary snapshot through AWS Backup`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No (deprecated)`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Skip creating primary snapshot.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Backup vault name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of AWS Backup vault used to make EFS snapshots.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ARN of role for backup vault`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`ARN of a role which will be passed to AWS Backup to execute EFS backup job.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replicas storing bucket name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No (deprecated)`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a bucket used to store EFS filesystem replicas.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Subnet ID`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No (deprecated)`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`ID of a subnet in which an EC2 instance copying data from EFS to S3 will be launched.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Security group ID`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No (deprecated)`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`ID of a security group which will be attached to an EC2 instance copying data from EFS to S3.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ARN of an instance profile`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No (deprecated)`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`ARN of an EC2 instance profile which will be used to launch an EC2 instance copying data from EFS to S3.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Boolean flag determining if EFS snapshots should be replicated to vault account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Vault account id`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Id of vault account used for storing backup replicas.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication region`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`An AWS region in which snapshot replicas will be stored.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ARN of a replica backup vault`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`An ARN of a AWS Backup Vault used to replicate EFS snapshot.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2 {...{
      "id": "add-setup-for-aws-china-ec2"
    }}>{`Add Setup for AWS China EC2`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`ws-cn-ec2`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter rest required fields to the setup form and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Boolean flag determining if EC2 snapshots should be replicated to vault account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication region`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`An AWS region in which snapshot replicas will be stored.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Share snapshot back`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Causes snapshots replicas stored in a `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account to be shared with a `}<inlineCode parentName="td">{`PROTECTED`}</inlineCode>{` account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replicas encryption KMS key ARN or alias`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Alias or ARN of a KMS key which will be used to encrypt snapshots replicas in `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account. Key must exist in replication region. Correct value is a string without `}<inlineCode parentName="td">{`alias/`}</inlineCode>{` prefix or ARN.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Default KMS key replacement`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`If a snapshot located in a `}<inlineCode parentName="td">{`PROTECTED`}</inlineCode>{` account, is going to be re-encrypted in source account and copied into `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account (e.g. source snapshot is encrypted by a default AWS managed KMS key) or for resources in Beijing region (sharing encrypted snapshot is not supported in this region) then you need to provide a custom KMS key (alias, ARN or key id) that will be used to re-encrypt the snapshot before replication.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Vault account id`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`ID of a vault account used for storing snapshot replicas.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Force encryption of unencrypted resources replicas`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Forces snapshot's replica to be encrypted using KMS key specified in Replicas encryption KMS key setting, even if original resource is unencrypted.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2 {...{
      "id": "add-setup-for-aws-china-ebs"
    }}>{`Add Setup for AWS China EBS`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`aws-cn-ebs`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter rest required fields to the setup form and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Boolean flag determining if EBS snapshots should be replicated to vault account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication region`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`An AWS region in which snapshot replicas will be stored. If not specified then snapshots will not be replicated.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Share snapshot back`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Causes snapshots replicas stored in a `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account to be shared with a `}<inlineCode parentName="td">{`PROTECTED`}</inlineCode>{` account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replicas encryption KMS key ARN or alias`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Alias or ARN of a KMS key which will be used to encrypt snapshots replicas in `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account. Key must exist in replication region. Correct value is a string without `}<inlineCode parentName="td">{`alias/`}</inlineCode>{` prefix or ARN.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Default KMS key replacement`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`If a snapshot located in a `}<inlineCode parentName="td">{`PROTECTED`}</inlineCode>{` account, is going to be re-encrypted in source account and copied into `}<inlineCode parentName="td">{`VAULT`}</inlineCode>{` account (e.g. source snapshot is encrypted by a default AWS managed KMS key) or for resources in Beijing region (sharing encrypted snapshot is not supported in this region) then you need to provide a custom KMS key (alias, ARN or key id) that will be used to re-encrypt the snapshot before replication.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Vault account id`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`ID of a vault account used for storing snapshot replicas.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2 {...{
      "id": "add-setup-for-aws-china-redshift"
    }}>{`Add Setup for AWS China Redshift`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`aws-cn-redshift`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter rest required fields to the setup form and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2 {...{
      "id": "add-setup-for-azure-vm"
    }}>{`Add Setup for Azure VM`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`azure-vm`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter rest required fields to the setup form and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2 {...{
      "id": "add-setup-for-azure-sap-hana"
    }}>{`Add Setup for Azure SAP HANA`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`azure-sap-hana`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter rest required fields to the setup form and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2 {...{
      "id": "add-setup-for-gcp-vm"
    }}>{`Add Setup for GCP VM`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`gcp-vm`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter rest required fields to the setup form and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Use Regional location for primary backup`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Use Regional location (instance region) instead of Multi-Regional location to create primary snapshots.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Boolean flag determining if GCP VM snapshots should be replicated to vault account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Vault account id`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`ID of a vault account used for storing snapshot replicas.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication multi-region`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if replication is enabled and Use Regional location for replication is disabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a multi-region to which snapshots will be replicated`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Use Regional location for replication`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Use Regional location instead of Multi-Regional location to create replica snapshots.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Replication region`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes, if Use Regional location for replication is enabled`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`A GCP region in which snapshot replicas will be stored.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
    <h2 {...{
      "id": "add-setup-for-ibm-cloud-vm"
    }}>{`Add Setup for IBM CLOUD VM`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose "Settings" from the navigation bar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose "Setups" tab and click "Create new" button.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Type custom `}<inlineCode parentName="p">{`Setup name`}</inlineCode>{` and select `}<inlineCode parentName="p">{`ibmcloud-vm`}</inlineCode>{` from `}<inlineCode parentName="p">{`"Resource type"`}</inlineCode>{` field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter rest required fields to the setup form and click "Save" button.`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Setup name`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Name of a setup.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Resource type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Type of resource you want to create a setup for.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Prehook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called before a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Posthook`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint which will be called after a backup attempt.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook type`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`AWS Lambda or HTTP endpoint.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`- Hook endpoint`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Lambda ARN or HTTP endpoint URL.`}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      